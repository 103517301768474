





















































































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

.v-card { border-radius: 16px !important; }

.v-btn { text-transform: uppercase; }

.accountTypeButton {
  color: $colorAccent;
  border: 3px solid transparent;
  border-radius: 16px;
  padding: 8px;
  margin: 8px 0px 8px;
  width: 100%;
  text-align: left;

  &-icon {
    color: $colorAccent;
  }

  &:hover {
    color: $colorPrimary;
    border: 3px solid $colorPrimary;

    .accountTypeButton-icon {
      color: $colorPrimary;
    }
  }
}

.selectAccountTypeDiv { margin: 0px 16px; }
